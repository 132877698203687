.lading-page {
  // padding: 40px 0;
  width: 100%;
  height: 100%;
  .hero-section {
    width: 100%;
    // min-height: 650px;
    height: calc(100vh - 4px);
    position: relative;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(
      to top right,
      #00008b,
      #000,
      #000,
      purple
    );
    .bg-video {
      object-fit: cover;
      position: absolute;
      inset: 0;
      height: 100%;
      width: 100%;
    }
    .overlay {
      background: rgba(0, 0, 0, 0.8);
      inset: 0;
      overflow: hidden;
      position: absolute;
      z-index: 1;
    }
    .wrap {
      height: 100%;
      z-index: 2;
      .hero-block {
        width: 100%;
        @include media("<=tablet") {
          text-align: center;
        }

        .slug {
          color: #fff;
          font-size: 50px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
          @include media("<=tablet") {
            font-size: 30px;
          }
        }
        .desc {
          color: #eaeaea;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 145.023%;
          margin: 20px 0;
          @include media("<=tablet") {
            font-size: 16px;
          }
        }
        .btn-mint {
          width: max-content;
          color: #000;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
          border-radius: 8px;
          background: #fff;
          border-color: #fff;
          padding: 12px 20px;
          @include media("<=tablet") {
            margin: 0 auto;
          }
        }
      }
    }
  }
  .total-color-section {
    background: #000;
    min-height: 110px;
    padding: 30px 12px;
    border-top: 1px solid #2c2c2c;
    border-bottom: 2px solid #2c2c2c;
    .t-color-title {
      color: #fff;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .t-color-desc {
      margin-top: 12px;
      color: #eaeaea;
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
    }
  }
  .about-us-section {
    background: #000;
    max-height: 730px;
    width: 100%;
    padding: 40px 0;
    @include media("<=tablet") {
      max-height: 1100px;
      text-align: center;
    }
    .wrap {
      .about-us-block {
        gap: 60px;
        width: 100%;
        .left-side {
          .sec-title {
            color: #fff;
            font-size: 30px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .sec-desc {
            color: #eaeaea;
            font-size: 16px;
            font-style: normal;
            font-weight: 300;
            margin: 26px 0;
          }
          .btn-join {
            width: max-content;
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: uppercase;
            border-radius: 8px;
            background: #fff;
            border-color: #fff;
            padding: 12px 20px;
            @include media("<=tablet") {
              margin: 0 auto;
            }
          }
        }
        .right-side {
          .white-1 {
            left: 100px;
            z-index: 1;
            position: relative;
            background-color: white;
            width: 306.61px;
            height: 383px;
            border-radius: 5px;
            display: flex;
            justify-content: end;
            align-items: center;
            @include media("<=tablet") {
              width: 200px;
              height: 283px;
              border-radius: 3px;
              left: 70px;
            }

            .purple {
              margin: 30px 0 30px 0;
              position: absolute;
              top: 1;
              width: 270px;
              height: 323px;
              background-color: #ffbf98;
              border: 4px solid #000;
              @include media("<=tablet") {
                width: 170px;
                height: 223px;
              }
            }
          }
          .white-2 {
            left: 250px;
            bottom: 300px;
            z-index: 2;
            position: relative;
            background-color: white;
            width: 306.61px;
            height: 383px;
            border-radius: 5px;
            display: flex;
            justify-content: end;
            align-items: center;
            @include media("<=tablet") {
              width: 200px;
              height: 283px;
              border-radius: 3px;
              left: 130px;
              bottom: 200px;
            }

            .red {
              margin: 50px 0 60px 0;
              position: absolute;
              width: 270px;
              height: 293px;
              background-color: #f8f083;
              border: 4px solid #000;
              @include media("<=tablet") {
                width: 170px;
                height: 203px;
                margin: 60px 0;
              }
            }
          }
          .white-3 {
            left: 50px;
            bottom: 550px;
            z-index: 3;
            position: relative;
            background-color: white;
            width: 306.61px;
            height: 383px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            @include media("<=tablet") {
              width: 200px;
              height: 283px;
              border-radius: 3px;
              left: 20px;
              bottom: 400px;
            }

            .orange {
              margin: 30px 0 0px 0;
              width: 270px;
              height: 343px;
              background-color: #51ccdf;
              border: 4px solid #000;
              @include media("<=tablet") {
                width: 170px;
                height: 243px;
              }
            }
          }
        }
      }
    }
  }
}
.content {
  background-color: #000;
  padding: 80px 0;
  margin: 50px 0;
  margin-bottom: 150px;
  border-top: 1px solid #2c2c2c;
  border-bottom: 2px solid #2c2c2c;
  .contentWrap {
    text-align: center;
    .contentHeading {
      color: #fff;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include media("<=tablet") {
        font-size: 20px;
      }
    }
    .video-block {
      padding: 0 8%;
      .video-container {
        margin: 30px 0;
        height: 460px;
        width: 100%;
        border-radius: 8px;
        border-radius: 20px;
        object-fit: cover;
        @include media("<=tablet") {
          border-radius: 10px;
        }
      }
    }
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      @include anim;
    }
  }
}
.shades {
  min-height: 730px;
  background-color: #000;
  @include media("<=tablet") {
    margin-bottom: 50px;
  }
  .shadeswarp {
    color: #fff;
    text-align: center;
    .shades-des {
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 30px;
      @include media("<=tablet") {
        font-size: 16px;
      }
    }
    .shades-heading {
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include media("<=tablet") {
        font-size: 20px;
      }
      .shades-span {
        background: linear-gradient(
          268deg,
          #ff00e5 44.93%,
          #4200ff 67.07%,
          #0085ff 78.14%
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .white-2 {
    position: relative;
    background-color: white;
    width: 274px;
    height: 343px;
    border-radius: 5px;
    display: flex;
    justify-content: end;
    align-items: center;
    @include media("<=tablet") {
      width: 200px;
      height: 283px;
      border-radius: 3px;
    }

    .red {
      margin: 50px 0 60px 0;
      position: absolute;
      width: 224px;
      height: 224px;
      background-color: #ff0000;
      border: 4px solid #000;
      @include media("<=tablet") {
        width: 170px;
        height: 203px;
        margin: 60px 0;
      }
    }
  }
}

.things {
  background-color: #000;
  min-height: 730px;
  .thingwrap {
    text-align: center;
    .thing-heading {
      color: #fff;
      text-align: center;
      font-family: Inter;
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include media("<=tablet") {
        font-size: 20px;
      }
    }
    .thing-desc {
      color: #eaeaea;
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 145.023%;
      margin: 24px 0;
      @include media("<=tablet") {
        font-size: 12px;
        margin: 20px 0;
      }
    }
  }
  .thi {
    .thi-B {
      margin: 80px 0;
      @include media("<=tablet") {
        margin: 20px 0;
      }
      .thi-Box-1 {
        border-radius: 5px;
        border: 2px solid #fff;
        background: linear-gradient(226deg, #db00ff 0%, #4200ff 66.34%);
        justify-content: center;
        align-items: center;
        display: flex;
        color: #fff;
        font-family: Inter;
        font-size: 25.641px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 49px;
        height: 49px;
        margin-right: 20px;
        cursor: pointer;
      }
      .thi-Box-desc {
        color: #fff;
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      .thi-Box {
        cursor: pointer;
        border-radius: 5px;
        background-color: transparent;
        border: 2px solid #fff;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #fff;
        font-family: Inter;
        font-size: 25.641px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 49px;
        height: 49px;
        margin-right: 20px;
      }
    }
    .thi-detail {
      background-color: #1b1b1b;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      padding: 20px 50px;
      .thi-D {
        margin-right: 90px;
        @include media("<=tablet") {
          margin-right: 0px;
          padding: 20px 10px;
        }

        .heading {
          color: #fff;
          text-align: start;
          font-size: 25.641px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin-bottom: 20px;
          @include media("<=tablet") {
            font-size: 18px;
          }
        }
        .desc {
          color: #fff;

          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin: 10px 0;
          @include media("<=tablet") {
            font-size: 14px;
          }
        }
      }

      .thi-img {
        cursor: pointer;
        position: relative;
        @include media("<=tablet") {
          padding: 20px 50px;
        }
        .img {
          position: absolute;
          z-index: 1;
          bottom: 30px;
          right: 130px;
        }
      }
    }
  }
}
.road {
  max-height: 1100px;
  justify-content: center;
  align-items: center;
  margin: 70px 0;
  .history-tl-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    @include media("<=tablet") {
      margin-left: 0px;
    }
    ul.tl {
      margin: 20px 0;
      padding: 0;
      display: inline-block;

      li {
        list-style: none;
        margin: 50px 0;
        margin-left: 133px;
        min-height: 50px;
        // border-radius: 10px;
        // // background: rgba(255, 255, 0, 0.1);
        // border-left: 10px solid transparent; /* Set the border to transparent */
        // border-image: linear-gradient(
        //     to bottom,
        //     #fa00ff 28.77%,
        //     #2554fd 37.17%,
        //     #ff005c 61.66%,
        //     #00d1ff 93.66%
        //   )
        //   1; /* 1 specifies the border image slice value */
        padding: 0 0 50px 30px;
        position: relative;

        &:last-child {
          border-left: 0;
        }

        &::before {
          z-index: 100;
          position: absolute;
          left: -11px;
          top: 4px;
          content: " ";
          border: 8px solid rgba(255, 255, 255, 0.74);
          border-radius: 500%;
          background: #fff;
          height: 20px;
          width: 20px;
          transition: all 500ms ease-in-out;
        }
      }

      .item-title {
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        @include media("<=tablet") {
          font-size: 18px;
        }
      }

      .item-detail {
        color: #fff;
        font-family: Inter;
        font-size: 10px;
        line-height: normal;
        margin: 10px 0;
      }

      .timestamp {
        position: absolute;
        width: 100px;
        left: -50%;
        text-align: right;
        color: #fff;

        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        @include media("<=tablet") {
          font-size: 18px;
          left: -70%;
        }
      }
    }
  }
}

.heading {
  color: #fff;

  text-align: center;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0;
  @include media("<=tablet") {
    font-size: 20px;
  }
}
.desc {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @include media("<=tablet") {
    font-size: 16px;
  }
}
.roadmap {
  min-height: 900px;
  width: 8px;
  height: 100%;
  border-radius: 20px;
  background: linear-gradient(
    to bottom,
    #fa00ff 28.77%,
    #2554fd 37.17%,
    #ff005c 61.66%,
    #00d1ff 93.66%
  );
  position: relative;
  right: 305px;
  bottom: 50px;
  @include media("<=tablet") {
    right: 202px;
  }
}

.meta {
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  @keyframes animatedgradient {
    0% {
      border-image-source: linear-gradient(
        #f79533,
        #f37055,
        #ef4e7b,
        #a166ab,
        #5073b8,
        #1098ad,
        #07b39b,
        #6fba82
      );
    }
    50% {
      border-image-source: linear-gradient(
        #5073b8,
        #1098ad,
        #07b39b,
        #6fba82,
        #f79533,
        #f37055,
        #ef4e7b,
        #a166ab
      );
    }
    100% {
      border-image-source: linear-gradient(
        #f79533,
        #f37055,
        #ef4e7b,
        #5073b8,
        #1098ad,
        #a166ab,
        #07b39b,
        #6fba82
      );
    }
  }

  .meta-box {
    width: 708px;
    margin: 100px auto;
    border-width: 7px;
    border-image: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    border-image-slice: 1;
    z-index: -1;
    animation: animatedgradient 3s linear infinite;
    background-size: 300% 300%;

    @include media("<=tablet") {
      width: 300px;
    }

    .meta-details {
      z-index: 1;
      padding: 40px;
      @include media("<=tablet") {
        padding: 20px;
      }
      .meta-heading {
        color: #fff;
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 20px 0;
        @include media("<=tablet") {
          font-size: 20px;
        }
      }
      .desc-heading {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin: 14px 0;
        @include media("<=tablet") {
          font-size: 14px;
        }
      }
      .desc {
        color: #dcdcdc;
        font-family: Inter;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        margin-top: 10px;
        @include media("<=tablet") {
          font-size: 12px;
        }
      }
    }
  }
}

.faq {
  min-height: 730px;
  margin: 20px 0;
  .faqwrap {
    align-items: start;
    border-radius: 5px;
    border: 2px solid #1b1b1b;
    background: rgba(119, 119, 119, 0.08);
    height: 410px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    @include media("<=tablet") {
      text-align: center;
      height: 780px;
    }
    .faq-details {
      @include media("<=tablet") {
        text-align: center;
        height: 580px;
        text-align: left;

      }
      .faq-heading {
        color: #fff;
        font-family: Inter;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        margin: 20px 0;
        text-align: left;
        @include media("<=tablet") {
          font-size: 20px;
        }
      }
      .desc {
        color: #fff;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        margin: 20px 0;
        @include media("<=tablet") {
          font-size: 14px;
        }
      }
      .btn-join {
        width: max-content;
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
        border-radius: 8px;
        background: #fff;
        border-color: #fff;
        padding: 12px 20px;
        margin: 20px 0;
        @include media("<=tablet") {
          margin: 0 auto;
        }
      }
    }
    .faqAry {
      color: #fff;
      width: 100%;

      @include media("<=tablet") {
        margin: 10px 0;
      }
      .faq-box {
        background-color: #171717;
        border-radius: 6px;
        margin-bottom: 12px;
        @include media("<=tablet") {
        }
        .icon {
          margin: 16px;
          height: 32px;
          width: 32px;
          padding: 2px;
          border: 1px solid #fff;
          border-radius: 100%;
          @include anim;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          @include media("<=tablet") {
            height: 22px;
            width: 22px;
          }
          svg {
            height: 30px;
            width: 30px;
            @include media("<=tablet") {
              height: 22px;
              width: 22px;
            }
          }
          &:hover {
            border: 1px solid $themeColor;
            svg {
              path {
                fill: $themeColor;
              }
            }
            &:hover {
              svg {
                path {
                  stroke: $themeColor;
                }
              }
            }
          }
        }
        .faq-heading {
          color: #ebebeb;
          font-family: Inter;
          font-size: 17.166px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-align: left;

        }
        .faq-desc {
          color: #eaeaea;
          font-family: Inter;
          font-size: 11.444px;
          font-style: normal;
          font-weight: 400;
          text-align: left;
        }
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 340px;
  display: flex;
  align-items: center;
  .swiper-slide {
    .color-block {
      height: 334.5px;
      width: 266px;
      background: #fff;
      // border-radius: 8px;
      padding:30px 25px 20px 30px;
      .color-box {
        height: 222px;
        width: 100%;
        // border-radius: 10px;
        border: 2px solid #000;
      }
    }
  }
}

.road-map-section {
  min-height: 730px;
  border-top: 1px solid #2c2c2c;
  border-bottom: 2px solid #2c2c2c;
  padding: 80px 0;
  margin: 50px 0;
  .mapWrap {
    .map-headings {
      .title {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .hdr-description {
        margin-top: 20px;
        margin-bottom: 50px;
        color: #fff;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
      }
    }
  }
}
