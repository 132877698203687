// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 10px 0px;
  background-color: #000;

  .wrap {
    @include media("<=tablet") {
      text-align: center;
      margin: 20px 0;
    }
    .desc-1 {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      @include media("<=tablet") {
        font-size: 12px;
      }
    }
    .desc-2 {
      color: #fff;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 8px 0;
      @include media("<=tablet") {
        font-size: 10px;
      }
    }
  }
}
